import React from "react"
export default function Wspolpraca() {
    return (
        <div className="row bg-jslightgray text-jsblack px-3 py-5" id="list-item-5">
            <div className="col-md-12  my-auto ">
                <h2 className="text-center pb-2">Jak będzie wyglądała nasza współpraca? </h2>
                <div className="row reveal " >
                    <ol>
                        <li className="pb-2"><b>Skontaktuj się ze mną za pomocą formularza lub mailowo.</b> Opisz zapotrzebowanie na teksty i zostaw kontakt. Odezwę się w ciągu maksymalnie 24 godzin ze wstępną wyceną (wystawiam faktury VAT). Mogę wykonać dla Ciebie także płatny tekst próbny.
</li>
                        <li className="pb-2">Po wspólnej akceptacji warunków współpracy podeślę Ci <b>prostą formatkę z briefem</b> do wypełnienia oraz (jeśli będzie to konieczne) wzór umowy o współpracy.
</li>
                        <li className="pb-2">Po otrzymaniu briefu i podpisaniu umowy <b>niezwłocznie zabieram się do działania!</b>
                        </li>
                        <li className="pb-2">W ustalonym terminie <b>podsyłam gotowe teksty i czekam na Twój feedback.</b> W cenie usługi 2 razy możesz zgłosić poprawki do copy.
</li>
                        <li className="pb-2"><b>I już!</b> Ty cieszysz się profesjonalnymi tekstami, ja – kolejną udaną współpracą :)

                                </li>
                    </ol>

                </div>
            </div>
        </div>
    )
}