import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
// import { Link } from "gatsby"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Description from "../components/description"
import Navbar from "../components/navbar"
import Sidebar from "../components/sidebar"

import Portfoliotags from "../components/portfoliotags"
import Footer from "../components/footer"
const portfolio = ({ data }) => {

  const { allContentfulPortfolio: { nodes: entries }, } = data

  return (
    <Layout>

      <div className={`bg-jswhite text-jslightgray `} data-spy="scroll" data-target="#list" data-offset="250">
        <div className="container-fluid pt-md-5 pt-sm-0">
          <Navbar />
          <div className="row">
            <div className="col-md-8 offset-md-2 order-sm-2 order-md-1">
              <Description
                name="Izabela Malinowska portfolio"
                link="/"
                buttonName=""
                description="" />
              <div className="row bg-jslightgray text-jsblack px-3 " >
                <div className="col-md-12 my-auto py-3 ">
                  <div className="pb-3">
                    <Portfoliotags />
                  </div>
                  <div className="row text-jslightgray">


                    {entries.map((entry) => {
                      return (

                        <div class="col-6 col-sm-4 col-md-4 col-xl-2  content-box gx-1 text-center   pb-4">

                          <a rel="noreferrer" target="_blank" href={entry.pdf.file.url} activeClassName="active">
                            <Image className="portfolio-image m-2" fluid={entry.Thumbnail.fluid}></Image>
                          </a>
                          <p className=" m-0 text-jsdarkgray ">{entry.title}</p>
                          {/* <p className="pl-2 m-0 pb-2 text-jsdarkgray">{entry.tag}</p> */}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
{
  allContentfulPortfolio {
    nodes {
      title
      Thumbnail {
        id
        fluid {
          ...GatsbyContentfulFluid
        }
      }
        pdf {
        file {
          url
        }
      }
      tag
      slug
   
    }
  }
}


`

export default portfolio
