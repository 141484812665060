
import { Link } from "gatsby"
import React from "react"
import Typewriter from 'typewriter-effect';
export default function Kontakt() {

    return (

        <div className="row bg-jslightgray text-jsdarkgray px-3 text-jsdarkgray" id="kontakt">
            <div className="col-md-12 bg-jslightgray py-5 ">



                <h2 className="pb-2 d-inline" id="cursor2">
                    <Typewriter
                        options={{
                            strings: ['Skontaktuj się', 'Poznaj wycenę'],
                            autoStart: true,
                            loop: true,
                            delay: "natural",
                        }}
                    />
                </h2>



                <form name="jednymSlowem" data-netlify="true" method="post" data-netlify-honeypot="bot-field">
                    <p className="d-none">
                        <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <input type="hidden" name="form-name" value="jednymSlowem" />

                    <div className="row">
                        <div className="col-sm-6">
                            <div className=" row">
                                <div >
                                    <input required id="Imie" name="Imie" placeholder="Imię i nazwisko" type="text" className="form-control" />
                                </div>
                            </div>
                            <div className=" row">
                                <label className="col-4"></label>
                                <div >
                                    <input required id="email" name="email" placeholder="E-mail" type="email" className=" form-control" />
                                </div>
                            </div>
                            <div className="row">

                                <div >
                                    <input id="Numer" name="Numer" placeholder="Numer telefonu" type="tel" className="form-control" />
                                </div>
                            </div>
                            <div className=" row">
                                <label className="col-3 col-form-label" htmlFor="rodzaj">Rodzaj tekstu</label>
                                <div className="col-9 pt-2">
                                    <select required id="rodzaj" name="rodzaj" className="custom-select w-100">
                                        <option value="Artykuł">Artykuł</option>
                                        <option value="strona WWW">Strona www / landing page</option>
                                        <option value="Mailing">Mailing</option>
                                        <option value="Wpisy w social mediach">Wpisy w mediach społecznościowych</option>
                                        <option value="Opis produktu">Opis produktu</option>
                                        <option value="Druk">Materiał drukowany</option>
                                        <option value="Inne">Inne</option>
                                    </select>
                                </div>
                            </div>

                            <div className=" row">
                                <div>
                                    <label htmlFor="termin">Oczekiwany termin realizacji</label>
                                    <input id="datepicker" name="termin" type="date" className="form-control" />
                                </div>
                            </div>
                            <div className=" row">
                                <div >
                                    <input id="Tematyka" name="Tematyka" placeholder="Opisz krótko zlecenie" type="text" className="form-control" />
                                </div>
                            </div>
                            <div className=" row pt-2">
                                <label>Czy potrzebujesz researchu po stronie copywritera?</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="research" id="inlineCheckbox1" value="Potrzebny research" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Tak</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="research" id="inlineCheckbox2" value="Nie p  otrzebny research" />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Nie</label>
                            </div>
                        </div>
                        <div className="col-sm-6">

                            <div className="row">
                                <div>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input required name="zgoda1" id="checkbox_0" type="checkbox" className="custom-control-input" value="polityka" />
                                        <label htmlFor="checkbox_0" className="custom-control-label d-inline"> <small>Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z regulaminem i  <Link to="/polityka">polityką prywatności</Link></small></label>
                                    </div>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input required name="zgoda2" id="checkbox_1" type="checkbox" className="custom-control-input" value="przetwarzanie" />
                                        <label htmlFor="checkbox_1" className="custom-control-label d-inline"> <small>Wyrażam zgodę na przetwarzanie podanych danych osobowych w celach realizacji zgłoszenia. Administratorem Twoich danych osobowych jest Jednym Słowem Copywriting Izabela Malinowska, NIP: 7393844803. Twoje dane osobowe sa przetwarzane w celu odpowiedzi na Twoje zapytanie dotyczące oferowanych usług.</small></label>
                                    </div>
                                </div>
                            </div>
                            <div className=" row">
                            </div>
                        </div>
                    </div>
                    <button name="submit" type="submit" className="btn btn-jsorange mt-2">Wyceń tekst</button>
                </form>



            </div>
        </div>
    )
}