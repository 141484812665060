import React from 'react'
import Navbar from "../components/navbar"
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import Footer from "../components/footer"
const polityka = () => {
    return (
        <Layout>

            <div className="bg-jswhite text-jslightgray"  >
                <div className="container-fluid pt-md-5 pt-sm-0">
                    <Navbar />
                    <div className="row">
                        <div className="col-md-8 offset-md-2 order-sm-2 order-md-1">

                            <div className="row bg-jslightgray text-jsblack px-3 " >
                                <div className="col-md-12 my-auto py-4">
                                    <div className="py-3">

                                    </div>
                                    <div className="row text-jsdarkgray">

                                        <p>Administratorem danych osobowych jest Jednym Słowem Copywriting Izabela Malinowska, NIP:<br />7393844803, REGON: 386224766. Dane wpisane w formularzu kontaktowym przetwarzane są<br />w celu udzielenia odpowiedzi na przesłane zapytanie zgodnie z Polityką prywatności.</p>
                                        <p><strong>Polityka Prywatności</strong><br />Poniżej opisuję sposoby, w jakie przetwarzam i przechowuję Twoje dane.<br />Administratorem strony jednymslowem.pl oraz danych, kt&oacute;re za jej pośrednictwem<br />przekazujesz, jest Jednym Słowem Copywriting Izabela Malinowska. Działalność wpisana jest<br />do Centralnej Ewidencji i Informacji o Działalności Gospodarczej - NIP: 7393844803, REGON:<br />386224766.</p>
                                        <p><strong>Najważniejsze informacje</strong><br />Strona ma charakter wizyt&oacute;wki internetowej. Nie prowadzę wysyłki mailing&oacute;w czy newsletter&oacute;w.<br />Możesz się ze mną skontaktować za pośrednictwem poczty e-mail oraz formularza<br />kontaktowego umieszczonego na stronie. Kontaktując się, przekazujesz mi swoje dane<br />osobowe (imię, adres e-mail i/lub nr telefonu). Gwarantuję poufność i bezpieczeństwo Twoich<br />danych.<br />Dane kontaktowe przekazywane w formularzu kontaktowym lub poprzez bezpośredni kontakt<br />mailowy przetwarzam tylko i wyłącznie w celu wyceny zlecenia, nawiązania wsp&oacute;łpracy (np.<br />przygotowanie umowy czy rozliczenia) lub odpowiedzi na Twoje pytanie.<br />Korzystam dodatkowo z narzędzi analitycznych (Google Analytics), zbierających anonimowe<br />informacje na temat odwiedzin strony oraz podstron. Informacje zbierane przez Google<br />Analytics to m.in.: liczba odwiedzanych podstron, czas spędzony na stronie, źr&oacute;dła ruchu.<br />Używanie tego narzędzia wiąże się z wykorzystaniem plik&oacute;w cookies należących do firmy<br />Google.<br />&nbsp;<br /><strong>Dane osobowe</strong><br />Administratorem danych osobowych jest Jednym Słowem Copywriting Izabela Malinowska<br />wpisana do Centralnej Ewidencji i Informacji o Działalności Gospodarczej - NIP: 7393844803,<br />REGON: 386224766.</p>
                                        <p>Podanie danych osobowych jest dobrowolne, ale niezbędne do skontaktowania się ze mną lub<br />podpisania umowy.<br />W związku z przetwarzaniem Twoich danych osobowych, przysługuje Ci:</p>
                                        <ul>
                                            <li>prawo do żądania dostępu do swoich danych osobowych, ich sprostowania, usunięcia</li>
                                            <li>(prawo do bycia zapomnianym) lub ograniczenia przetwarzania;</li>
                                            <li>prawo do wniesienia sprzeciwu wobec przetwarzania;</li>
                                            <li>prawo do przenoszenia danych;</li>
                                            <li>prawo do cofnięcia zgody na przetwarzanie danych osobowych w określonym celu,<br />jeżeli uprzednio wyraziłeś taką zgodę;</li>
                                            <li>prawo do wniesienia skargi do organu nadzorczego.</li>
                                        </ul>
                                        <p>W celu zapewnienia ochrony i bezpieczeństwa Twoim danym osobowym, stosuję odpowiednie<br />środki techniczne i organizacyjne, m.in. bezpieczny protok&oacute;ł szyfrowania komunikacji (SSL).</p>
                                        <p><strong>Statystyka i analityka</strong><br />Śledzę statystyki strony, m.in. liczbę odwiedzających, czas spędzony na stronie, odwiedzone<br />podstrony. W tym celu korzystam z narzędzia Google Analytics, bazującego na plikach cookies<br />od firmy Google LLC. Zbierane za pośrednictwem GA informacje są całkowicie anonimowe. W<br />żaden nie pozwalają na identyfikację Ciebie.</p>
                                        <p><strong>Logi serwera</strong><br />Strona korzysta z hostingu zewnętrznego. Każde wejście na stronę wiąże się z przesyłaniem<br />zapytań do serwera. Zapytania są zapisywane w jego logach. Takie dane obejmują m.in.: adres<br />IP, datę i czas serwera, informacje dot. przeglądarki internetowej i systemu operacyjnego<br />użytkownika. Dane nie są kojarzone z konkretnymi użytkownikami strony i nie są wykorzysytane<br />w celu identyfikacji os&oacute;b tu zaglądajacych. To tylko i wyłącznie element pomocniczy w<br />administrowaniu stroną. Więcej o ochronie danych osobowych stosowanych przez firmę<br />hostującą stronę przeczytasz w tym miejscu.</p>
                                        <p><strong>Zmiany i aktualizacje</strong><br />Zastrzegam sobie prawo do zmian oraz aktualizacji treści niniejszej polityki prywatności w<br />wybranym momencie, dlatego proszę o regularne sprawdzanie jej treści.</p>
                                        <p><strong>Masz jakieś pytania? Zapraszam do kontaktu:</strong></p>

                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                        <Sidebar />
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default polityka
