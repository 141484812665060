import React from "react"
import { graphql } from "gatsby"

import Navbar from "../components/navbar"
import Sidebar from "../components/sidebar"
import Layout from "../components/layout"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
export const query = graphql`
  query GetSinglePost($post: String) {
    post: contentfulBlogPost(slug: { eq: $post }) {
      header
      excerpt
      id
      thumb {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      rich: childContentfulBlogPostPostRichTextNode {
        json
      }
    }
  }
`
const postTemplate = ({
  data: {
    post: {
      header,
      thumb: { fluid },
      rich: { json },
    },
  },
}) => {
  return (
    <Layout>
      <div
        className={`bg-jswhite text-jslightgray `}
        data-spy="scroll"
        data-target="#list"
        data-offset="250"
      >
        <div className="container-fluid pt-md-5 pt-sm-0">
          <Navbar />
          <div className="row">
            <div className="col-md-8 offset-md-2 order-sm-2 order-md-1">
              {/* <Description name="blog" link="/" buttonName="Strona główna" /> */}
              <div
                className="row   pl-md-3 pl-sm-0 bg-jsdarkgray text-jsmidgray"
                id="list-item-2"
              >
                <div className="col-lg-7 col-md-8   my-auto py-3 py-sm-4 py-md-5 ">
                  <h2 className="pb-2">{header}</h2>
                  <p>{documentToReactComponents(json)}</p>

                  {/* <Link to={post.slug}>
                                        <p className="text-right pr-4">Czytaj więcej</p>
                                    </Link> */}
                  <Link to="/blog-post">
                    <button
                      type="button"
                      className="btn btn-jslightgray text-jsdarkgray"
                    >
                      Wróć
                    </button>
                  </Link>
                </div>
                <div className="col-lg-5 col-md-4  p-3 min-vh-25">
                  <Image className="post-image" fluid={fluid}></Image>
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default postTemplate
