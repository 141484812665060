import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from 'gatsby-image'
import img from '../images/writing.jpg'

const getImages = graphql`
{
  fixed: file(relativePath: {eq: "test-folder/test.jpg"}) {
    childImageSharp {
      fixed {
        ...GatsbyImageSharpFixed
      }
    }
  }
  fluid: file(relativePath: {eq:"test-folder/test2.png"}){
    childImageSharp{
      fluid{
       ...GatsbyImageSharpFluid
      }
    }
  }
}
`

const Images = () => {
  const data = useStaticQuery(getImages)
  console.log(data)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <img src={img} width="100%" alt="" />
        </div>
        <div className="col">
          <Image fixed={data.fixed.childImageSharp.fixed} alt="" />
        </div>
        <div className="col">
          <Image fluid={data.fluid.childImageSharp.fluid} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Images
