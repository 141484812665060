


import React from "react"

import scrollTo from 'gatsby-plugin-smoothscroll';
export default function DlaczegoWarto() {
    return (
        <div className="row bg-jsdarkgray text-jsmidgray pr-md-3 pr-sm-0" id="list-item-2">
            <div className="col-lg-5 col-md-4  image-writer min-vh-25">

            </div>
            <div className="col-lg-7 col-md-8   my-auto py-5 ">
                <h2 className="pb-2">Dlaczego warto?</h2>
                <p className="reveal" >
                    Wychodzę z&nbsp;założenia,
                    że dobry copywriting powinien przede wszystkim <b>sprzedawać</b> i&nbsp;tym kieruję się
                    podczas
                    tworzenia tekstów.<br />Jeśli zatem:<br />

                    <ul className="pl-5">
                        <li>szukasz dobrego copywritera, który skupi się na tym, by Twoja oferta była atrakcyjna dla kupujących, a <b>sprzedaż rosła z dnia na dzień</b>,</li>

                        <li>potrzebujesz <b>przekonujących komunikatów marketingowych</b> na potrzeby strony www, bloga, mailingu czy sklepu internetowego,</li>

                        <li>zależy Ci na jakości, niezawodności, dobrej komunikacji z wykonawcą oraz <b>terminowości</b>...</li>

                    </ul>



                    … to wszystko znajdziesz właśnie u&nbsp;mnie!<br></br>
                    Zapraszam – <b><u className="pointer" onClick={() => scrollTo('#kontakt')}>skontaktuj się ze mną</u></b> w&nbsp;celu omówienia szczegółów współpracy.
                </p>
            </div>

        </div>
    )
}