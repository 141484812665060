
import React from "react"
export default function WCzymMogePomoc() {
    return (
        <div className="row bg-jsdarkgray text-jsmidgray px-3 py-5" id="list-item-4">
            <div className="col-lg-12  my-auto ">
                <h2 className="text-center pb-4">Co dla Ciebie napiszę?</h2>
                <div className="row reveal" >
                    <div className="col-xl col-lg-12 pb-2">
                        <b>Teksty na strony WWW i&nbsp;na potrzeby mailingów</b><br />
                                        Treści stron firmowych, landing pages, newsletterów i&nbsp;mailingów.
                            </div>
                    <div className="col-xl col-lg-12 pb-2">
                        <b>Teksty do materiałów drukowanych</b><br />
                        Treść i&nbsp;koncepcja kreatywna materiałów marketingowych ukazujących się w&nbsp;druku
                        (broszur,
                        ulotek, plakatów, artykułów
                        sponsorowanych).
                            </div>
                    <div className="col-xl col-lg-12 pb-2" id="test">
                        <b>Opisy produktów</b><br />
                        Profesjonalna prezentacja produktów w&nbsp;e-commerce, w&nbsp;tym tworzenie opisów
                        pozycjonujących
                        ofertę w&nbsp;wyszukiwarkach.
                            </div>
                    <div className="col-xl col-lg-12 pb-2">
                        <b>Prowadzenie profili marek i&nbsp;ekspertów w&nbsp;kanałach social media</b><br />
                        Tworzenie treści postów i&nbsp;reklam w&nbsp;mediach społecznościowych, kompleksowa
                        obsługa
                        profili firm i&nbsp;pojedynczych osób
                        (ghostwriting).
                            </div>
                    <div className="col-xl col-lg-12 pb-2">
                        <b>Content marketing + SEO Copywriting</b><br />
                                                Pisanie profesjonalnych artykułów contentowych i&nbsp;innych materiałów edukujących
                                                klientów
                                                o&nbsp;Twojej ofercie z&nbsp;zastosowaniem
                                                słów kluczowych.
                            </div>

                </div>
            </div>
        </div>
    )
}