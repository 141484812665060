import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"


const getImages = graphql`
{
    fluidimg: file(relativePath: { eq: "IzaFotka.jpg" }) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
            }
        }
    }
    podpis: file(relativePath: { eq: "signature.png" }) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`


const Omnie = () => {
    const data2 = useStaticQuery(getImages)





    return (
        <div className="row bg-jsdarkgray text-jsmidgray pl-3 " id="list-item-2">
            <div className="col-lg-9 col-md-8 col-8   my-auto py-5 ">
                <h2 className="pb-2">Zanim zaczniemy współpracę, pozwól że się przedstawię</h2>
                <p className="reveal">
                    Nazywam się Izabela Malinowska i jestem profesjonalnym copywriterem z <b>ponad 5-letnim doświadczeniem</b> w tworzeniu tekstów, które sprzedają produkty i usługi oraz budują rozpoznawalność i wiarygodność marek moich klientów. Specjalizuję się w marketingu B2B, a zwłaszcza w tematyce biznesowej, szkoleniowej, sprzedażowej i HR-owej. Przez 3 lata byłam odpowiedzialna za copywriting w „Harvard Business Review Polska”. Z zamiłowania chętnie piszę na tematy popkulturowe i literackie. Chcesz dowiedzieć się więcej? <b>Śmiało, pisz, chętnie odpowiem!</b>
                </p>

                <div className="col-xl-4 col-md-6 col-12 col-sm-8">
                    <Image className="img-fluid" fluid={data2.podpis.childImageSharp.fluid} />
                </div>
                <a href="https://www.linkedin.com/in/i-malinowska/" target="_blank" rel="noreferrer" className="btn btn-jsmidgray mt-2">Sprawdź moje doświadczenie</a>
            </div>
            <div className="col-lg-3 col-md-4 col-4  min-vh-25 px-0">
                <Image className="img-fluid" fluid={data2.fluidimg.childImageSharp.fluid} />
            </div>

        </div>
    )
}


export default Omnie
