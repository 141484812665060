import React from "react"
import { Link } from "gatsby"
export default function Navbar() {
  const [nav, setValue] = React.useState(true)
  console.log(nav)
  return (
    <div className="row sticky-top">
      <div className="col-md-12 px-0">
        <nav
          className="navbar navbar-expand-lg navbar-dark bg-jsblack d-flex d-sm-flex d-md-none"
          id="test"
        >
          <div className="container-fluid">
            <p className="navbar-brand m-0 text-jsmidgray">Jednym Słowem</p>
            <button
              onClick={() => setValue(!nav)}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* <div className={`collapse navbar-collapse ${nav ? "" : "show"}`} id="navbarSupportedContent"> */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link" >
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/portfolio" className="nav-link" >
                    Portfolio
                  </Link>
                </li>


              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}
