import React from "react"
import { Link } from "gatsby"

export default function Sidebar() {
    return (
        <div className="col-md-2 order-sm-1 d-none d-sm-none d-xs-none d-md-block ">
            <div className="sticky-top text-jsdarkgray">
                <ul className="list-group" id="list">
                    <li className="list-group-item">
                        <Link to="/" activeClassName="active" className="m-0 list-group-item list-group-item-action list-group-item-jslightgray">
                          
                                Strona główna
                           
                        </Link>
                    </li>

                    {/* <li className="list-group-item">

                        <Link to="/blog-post" activeClassName="active" className="m-0 list-group-item list-group-item-action list-group-item-jslightgray"
                        >
                            Blog
                        </Link>
                    </li> */}
                    <li className="list-group-item">
                        <Link to="/portfolio" activeClassName="active" partiallyActive={true} className="m-0 list-group-item list-group-item-action list-group-item-jslightgray">

                            Portfolio
                            </Link>
                    </li>
                </ul>
            </div>
        </div >
    )
}