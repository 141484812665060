import React from "react"
import cart from "../images/svg/001-shopping-cart.svg"
import briefcase from "../images/svg/002-briefcase.svg"
import hourglass from "../images/svg/003-hourglass.svg"
import fans from "../images/svg/004-fans.svg"
import search from "../images/svg/005-search.svg"


export default function CoZyskujesz() {
    return (
        <div className="row bg-jslightgray text-jsblack px-3 py-5" id="list-item-3">
            <div className="col-md-12  my-auto ">
                <h2 className="text-center pb-2">Co zyskujesz?</h2>
                <div className="row reveal"  >
                    <div className="col-lg pb-2 text-center">
                        <img alt="" className="img-fluid  mwpx  " src={cart} />
                        <p>zwiększysz sprzedaż</p>

                    </div>
                    <div className="col-lg pb-2 text-center">
                        <img alt="" className="img-fluid  mwpx  " src={search} />
                        <p>zadbasz o większą świadomość Twojego produktu/usługi wśród kupujących</p>
                    </div>
                    <div className="col-lg pb-2 text-center">
                        <img alt="" className="img-fluid  mwpx  " src={briefcase} />
                        <p>zbudujesz profesjonalny wizerunek – firmy, marki, produktu, Ciebie
                                    jako eksperta</p>
                    </div>

                    <div className="col-lg pb-2 text-center">
                        <img alt="" className="img-fluid  mwpx  " src={fans} />
                        <p>zwiększysz lojalność klientów</p>
                    </div>
                    <div className="col-lg pb-2 text-center">
                        <img alt="" className="img-fluid  mwpx   " src={hourglass} />
                        <p>oszczędzisz swój czas i&nbsp;pieniądze</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

