import React from 'react'
import { useState } from 'react';

import { graphql } from "gatsby"

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
export const query = graphql`
{
  pdftest:file(relativePath: {eq: "pdf/test.pdf"}) {
    id
    relativePath
    publicURL
  }
  
}
`

const Test = ({ data }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }


  console.log(data.pdftest.relativePath);
  return (
    <div className="container">
      <div className="row bg-jslightgray">
        <div className="col-12">
          <Document
            file={data.pdftest.publicURL}


            loading="chwilka..."
            onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber}

            />
          </Document>
          <div>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
        </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
        </button>
          </div>
        </div>
      </div>
    </div>


  )
}

export default Test
