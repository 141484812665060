import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar"
import Sidebar from "../components/sidebar"
const NotFoundPage = () => (
  <Layout>
    <SEO title="404 zabłądziłeś" />
    <div className="bg-jswhite text-jslightgray pb-3" >
      <div className="container-fluid pt-md-5 pt-sm-0">
        <Navbar />
        <div className="row">
          <div className="col-md-8 offset-md-2 order-sm-2 order-md-1">
            <div className="row bg-jswhite text-jsblack px-3 position-relative " id="list-item-1">
              <div className="col-md-12 my-auto py-4">
                <div className="main-header miss d-inline-block">
                  <h1 className="d-inline" id="cursor">Jednym Słowem|</h1>
                  <br />
                  <h2 className=" position-relative float-right " id="header-reveal">
                    zabłądziłeś...</h2>
                </div>
                <div className="col-md-6 px-0 position-relative">

                </div>
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
