import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import { Link } from "gatsby"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Navbar from "../components/navbar"
import Sidebar from "../components/sidebar"

const BlogPost = ({ data }) => {
  const { allContentfulBlogPost: { nodes: posts }, } = data
  let x = 0
  return (
    <Layout>

      <div className={`bg-jswhite text-jslightgray `} data-spy="scroll" data-target="#list" data-offset="250">
        <div className="container-fluid pt-md-5 pt-sm-0">
          <Navbar />
          <div className="row">
            <div className="col-md-8 offset-md-2 order-sm-2 order-md-1">
              {/* <Description name="blog" link="/" buttonName="Strona główna" /> */}


              {posts.map((post) => {

                x += 1;

                return (
                  <div className={"row pl-md-3 pl-sm-0  " + (x % 2 === 1 ? "bg-jsdarkgray text-jsmidgray" : "bg-jslightgray text-jsdarkgray")} id="list-item-2" key={post.id}>
                    <div className="col-lg-7 col-md-8   my-auto py-3 py-sm-4 py-md-5 ">
                      <h2 className="pb-2">{post.header}</h2>
                      <p>{post.excerpt}</p>
                      {/* {documentToReactComponents(post.blogpost.json)} */}
                      <Link to={post.slug}>
                        <button type="button" className={"btn btn-sm " + (x % 2 === 0 ? "btn-jsdarkgray text-jsmidgray" : "btn-jslightgray text-jsdarkgray")}>Czytaj więcej</button>
                      </Link>
                    </div>
                    <div className="col-lg-5 col-md-4  p-3 min-vh-25">
                      <Image className="post-image" fluid={post.thumb.fluid}></Image>
                    </div>

                  </div>

                )
              })}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
{
  allContentfulBlogPost(sort: {fields:createdAt}) {
    nodes {
      header
      excerpt
      slug
      id
      thumb {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      blogpost: childContentfulBlogPostPostRichTextNode {
        json
      }
    }
  }
}

`

export default BlogPost

