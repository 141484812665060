import React from 'react'

export default function footer() {
    return (
        <div className="row bg-jswhite text-jsdarkgray px-3 position-relative text-right " >
            <p className="text-right"> </p>

        </div>
    )
}
