import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

export default function Portfoliotags() {

  return (

    <StaticQuery
      query={graphql`
      {
        allContentfulPortfolio {
          group(field: tag) {
            fieldValue
          }
        }
      }
    `}

      render={data => (
        <>
          <Link to="/portfolio" activeClassName="active">
            <button type="button" class="btn btn-outline-jsdarkgray mr-3 mt-3 mt-lg-0">All</button>
          </Link>
          {data.allContentfulPortfolio.group.map((tag) => (
            <Link to={`/portfolio/${tag.fieldValue}`} activeClassName="active" partiallyActive={true}>
              <button type="button" class="btn btn-outline-jsdarkgray  mr-3 mt-3 mt-lg-0">{tag.fieldValue}</button>
            </Link>
          ))}

        </>
      )}

    />
  )
}






