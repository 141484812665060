import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import scrollTo from 'gatsby-plugin-smoothscroll';
const getImages = graphql`
{
    darksignature: file(relativePath: { eq: "signature-dark.png" }) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
            }
        }
    }
    heroimage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
            fluid (maxWidth: 1400,
            
            )
        {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`


export default function Description(props) {
    const image = useStaticQuery(getImages)
    let button;
    if (props.buttonName) {
        button = <button type="button" onClick={() => scrollTo('#kontakt')} className="btn btn-jsorange">{props.buttonName}</button>;
    } else {

    }

    return (

        <div className="row bg-jslightgray text-jsblack px-3 position-relative " id="list-item-1">
            <Image className="img-fluid hero" fluid={image.heroimage.childImageSharp.fluid} />
            <div className="col-md-12 my-auto py-4">
                {/* <div className="col-4 position-relative ">
                    <Image className="img-fluid" fluid={image.darksignature.childImageSharp.fluid} />
                </div> */}
                <div className="main-header d-inline-block">

                    <h1 className="d-inline" id="cursor">Jednym Słowem|</h1>
                    <br />
                    <h2 className=" position-relative float-right " id="header-reveal">
                        {props.name}</h2>

                </div>
                <div className="col-md-6 px-0 position-relative">

                    <p>{props.description}
                    </p>
                    {button}
                </div>
            </div>
        </div>
    )
}