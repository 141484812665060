import React from "react"


import Layout from "../components/layout"


import CoZyskujesz from "../components/cozyskujesz"
import WCzymMogePomoc from "../components/wczymmogepomoc"
import DlaczegoWarto from "../components/dlaczegowarto"
import Wspolpraca from "../components/wspolpraca"
import Kontakt from "../components/kontakt"
import Sidebar from "../components/sidebar"
import Description from "../components/description"
import Navbar from "../components/navbar"
import Omnie from "../components/omnie"
import Footer from "../components/footer"

const IndexPage = () => (

  <Layout>
    <div className="bg-jswhite text-jslightgray pb-3" data-spy="scroll" data-target="#list" data-offset="250">
      <div className="container-fluid pt-md-5 pt-sm-0">
        <Navbar />
        <div className="row">
          <div className="col-md-8 offset-md-2 order-sm-2 order-md-1">
            <Description
              name="Izabela Malinowska copywriting"
              link="/#list-item-6"
              buttonName="Poznaj wycenę"
              description={<>Pomagam firmom w <b>profesjonalnym opakowaniu w język korzyści produktów, usług i brandów.</b> Serdecznie zapraszam do kontaktu w sprawie współpracy!</>} />
            <DlaczegoWarto />
            <CoZyskujesz />
            <WCzymMogePomoc />
            <Wspolpraca />
            <Omnie />
            <Kontakt />
            <Footer />
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </Layout >

)

export default IndexPage
